/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    string, arrayOf, number, shape,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useUIDSeed } from 'react-uid';
import NupV2CollectionSlider from './NupV2CollectionSlider';
import NupV2Header from './NupV2Header';
import NupV2Panel from './NupV2Panel';
import { getSSRDeviceType } from '../../../../state/ducks/App/App-Selectors';
import { getActiveABTest } from '../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import { getPresentationFamily, getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import cleanText from '../../../helpers/contentstack/cleanText';

const themes = {
    mainContainerPadding: {
        default: {
            paddingBottom: 0,
        },
        variant_a: {
            paddingBottom: 50,
        },
    },
};

const useStyles = makeStyles((theme) => ({
    container: ({ stylesObj, carousel }) => ({
        maxWidth: 1400,
        width: '100%',
        margin: (carousel) ? '0px auto 84px auto' : '0 auto',
        paddingBottom: themes.mainContainerPadding[stylesObj?.presentation]?.paddingBottom || 0,
        backgroundColor: stylesObj?.background_color?.color || '#ffffff',
        [theme.breakpoints.down('md')]: {
            margin: '0px auto 32px auto',
        },
        [theme.breakpoints.down(599)]: {
            margin: '0px auto',
        },
    }),
    containerWithGrid: ({ stylesObj, carousel }) => ({
        maxWidth: 1400,
        width: '100%',
        margin: (carousel) ? '0px auto 84px auto' : '0 auto',
        paddingBottom: themes.mainContainerPadding[stylesObj?.presentation]?.paddingBottom || 0,
        backgroundColor: stylesObj?.background_color?.color || '#ffffff',
        [theme.breakpoints.down('md')]: {
            margin: '0px auto',
        },
        [theme.breakpoints.down(599)]: {
            margin: '0px auto',
        },
    }),
    containerFlower: {
        [theme.breakpoints.down('md')]: {
            padding: '0px 10px 50px 10px',
        },
        [theme.breakpoints.down(599)]: {
            margin: '0px auto 50px auto',
        },
    },
    panel: {
        display: 'flex',
        padding: '15px 0px',
        '& a': {
            display: 'inherit',
            fontSize: '16px',
            fontWeight: '400',
            textDecoration: 'none',
            color: theme.palette.cms?.black || ' #000',
        },

    },
    gridContainer: ({ gridProp }) => ({
        display: 'grid',
        gridTemplateColumns: `repeat(${gridProp?.itemsInRow}, ${gridProp?.fraction})`,
        gridTemplateRows: '1fr',
        columnGap: '1rem',
        [theme.breakpoints.down(599)]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
    }),
}));

const NupV2 = ({ data }) => {
    if (!data || Object.keys(data.reference?.[0]).length === 0) return null;
    const {
        presentation_style, header_section = {}, header_styling = {}, entry = [], arrow_icon_adjust, background = {}, show_passport_eyebrow, eyebrow_copy, carousel, nupv2_disable_linking, number_of_rows, ab_testing = {},
    } = data?.reference?.[0] || {};
    const abTestShopByCategory = useSelector(getActiveABTest('shopByCategory'));
    const stylesObj = { ...background }; // needed to update the immutable
    stylesObj.presentation = presentation_style || 'default';
    const ssrDeviceType = useSelector(getSSRDeviceType);
    const isFlowerBrand = useSelector(getPresentationFamily) === 'flower';
    const ffHasHomepageGridStructure = useSelector(getFeatureFlag('has-homepage-grid-structure'));
    const seed = useUIDSeed();
    const elementWidth = () => {
        // Base element widths on number of rows.
        // E.g. 10 items across 1 row = 10% width (+/-) each
        //      10 items across 2 rows = 20% width (+/-) each
        // if 1 row and two elements, create narrower gap between both thatn ususal.
        const elementCount = entry?.length || 0;
        const rowCount = number_of_rows || 1;
        let widthValue = 'auto';

        if (rowCount > 1) { // more than one row, distribute elements to rows by making widths relative to row count
            widthValue = `${100 / (elementCount / rowCount)}%`;
        }
        if (rowCount === 1) { // only one row, make widths even
            widthValue = `${100 / elementCount}%`;
        }

        return { width: widthValue };
    };

    const getGridStyle = () => {
        const rowCount = number_of_rows || 1;
        const elementCount = entry?.length || 0;
        let widthValue = 100;
        if (rowCount > 1) { // more than one row, distribute elements to rows by making widths relative to row count
            widthValue = `${100 / (elementCount / rowCount)}`;
        }
        if (rowCount === 1) { // only one row, make widths even
            widthValue = `${100 / elementCount}`;
        }

        // 24 for desktop
        const getFractions = 24 / Math.floor(100 / widthValue);
        return {
            widthValue: `${widthValue}%`,
            itemsInRow: Math.round(elementCount / rowCount),
            fraction: `${Math.floor(getFractions)}fr`,
        };
    };

    stylesObj.gridProp = getGridStyle();
    const classes = useStyles(stylesObj);
    /**
     * AB testing block
     */
    let itemsList = entry;
    if (ab_testing?.unique_id === 'shopByCategory' && entry?.length) {
        const shopByCategoryVariant = abTestShopByCategory?.type?.toLowerCase?.() || null;
        if (shopByCategoryVariant === 'variant 1') {
            // added default 5 to show as per ticket but it can be managed by optimize
            const showCount = abTestShopByCategory?.desktopItemCount || 5;
            itemsList = itemsList?.slice?.(0, showCount);
        } else if (shopByCategoryVariant === 'variant 2') {
            return <></>;
        }
    }

    // Just keeping the old layout, but we can remove that
    const renderAsGrid = true;

    const renderWithGrid = (
        <Grid className={classes.gridContainer} item xs={12}>
            {itemsList?.map((item) => (
                <div key={seed(item)} className={classes.panel}>
                    <NupV2Panel panelItem={item} headerSection={header_section} isLink={nupv2_disable_linking} />
                </div>
            ))}
        </Grid>
    );

    const renderWithoutGrid = itemsList?.map((item) => (
        <div key={seed(item)} className={classes.panel} style={ssrDeviceType === 'desktop' ? elementWidth() : {}}>
            <NupV2Panel panelItem={item} headerSection={header_section} isLink={nupv2_disable_linking} />
        </div>
    ));

    const render = renderAsGrid ? renderWithGrid : renderWithoutGrid;
    const containerCssStyle = ffHasHomepageGridStructure ? `${classes.containerWithGrid} ${isFlowerBrand ? classes.containerFlower : ''}` : `${classes.container} ${isFlowerBrand ? classes.containerFlower : ''}`;
    return (
        <Grid container className={containerCssStyle}>
            {(header_section?.heading || cleanText(header_section?.heading_rich) || header_section?.copy_rich || header_section?.cta_image || header_section?.add_passport_module) && (
                <Grid item xs={12}>
                    <NupV2Header
                        headerSection={header_section}
                        headingStyling={header_styling}
                        entry={itemsList}
                        showPassportEyebrow={show_passport_eyebrow}
                        eyebrowCopy={eyebrow_copy}
                        variantStyle={presentation_style}
                    />
                </Grid>
            )}
            {carousel ? (
                <NupV2CollectionSlider
                    collections={itemsList}
                    arrowIconAdjust={arrow_icon_adjust}
                />
            )
                : render}
        </Grid>
    );
};

NupV2.propTypes = {
    data: shape({
        title: string,
        reference: arrayOf(shape({
            header_section: shape({
                heading: string.isRequired,
            }),
            header_styling: shape({
                font_family: string,
                fontSize: number,
                font_weight: string,
                font_color: shape({
                    color: string,
                }),
            }),
            cta_link: shape({
                href: string,
                title: string,
            }),
            background: shape({
                color: string,
            }),
        })),
    }),
};
NupV2.defaultProps = {
    data: {},
};

export default (NupV2);

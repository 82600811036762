/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    arrayOf, object, shape, string,
} from 'prop-types';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Slider from 'react-slick';
import { useUIDSeed } from 'react-uid';
import { useSelector } from 'react-redux';
import NupV2Panel from './NupV2Panel';
import NavigationPaginate from '../Buttons/NavigationPaginate';
import { getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';

const styles = (theme) => ({
    mainSliderContainer: {
        width: '100%',
        paddingTop: '15px',
    },
    mainSliderContainerWithGrid: {
        width: '100%',
        paddingTop: '15px',
        '& div.slick-slider.carousel-container': {
            margin: 'auto !important',
        },
    },
    sliderContainer: {
        '& a': {
            display: 'inherit',
            fontSize: '16px',
            fontWeight: '400',
            textDecoration: 'none',
            color: theme.palette.cms?.black || ' #000',
        },
        position: 'relative',
        width: '100%',
        '& .slick-list': {
            overflow: 'hidden',
            margin: '0 auto',
            width: '100%',
            [theme.breakpoints.down('600')]: {
                paddingLeft: '0px !important',
            },
        },
        '& .slick-list .slick-track': {
            display: 'flex',
            flexDirection: 'row',
            columnGap: '1rem',
        },
        '& .slick-slide': {
            display: 'flex',
            verticalAlign: 'top',
            flexDirection: 'column',
        },
        '& .backwardArrow, & .forwardArrow': {
            cursor: 'pointer',
            bottom: '-45px',
            width: 40,
            height: 40,
            position: 'absolute',
        },
        '& .backwardArrow': {
            right: '70px',
            zIndex: 998,
        },
        '& .forwardArrow': {
            right: '20px',
        },
        '& .slick-disabled': {
            '& .back': {
                filter: 'invert(70%)',
            },
            '& .forward': {
                filter: 'invert(70%)',
            },
            cursor: 'not-allowed',
            borderColor: `${theme.palette.cms?.horizentalLineBorder || '#DEDEDE'} !important`,
            backgroundColor: '#00000008 !important',
            color: theme.palette.cms?.diabledBorderColor || '#DCDCDC',
        },
        [theme.breakpoints.down(599)]: {
            '& .slick-dots': {
                listStyle: 'none',
                display: 'flex !important',
                padding: '0',
                justifyContent: 'center',
                margin: '0',
                '& li': {
                    lineHeight: 1,
                },
                '& .slick-active': {
                    '& div': {
                        background: theme.palette.cms?.white || 'rgb(47, 47, 47)',
                    },
                },
                '& .slick-current': {
                    '& div': {
                        paddingRight: 5,
                    },
                },
            },
        },
    },
    dotNavigation: {
        width: 22,
        height: 2,
        cursor: 'pointer',
        display: 'inline-block',
        marginRight: 5,
        background: theme.palette.cms?.borderGrayColor || '#ededed',
    },
    iconAdjustTopCss: {
        '& .backwardArrow, & .forwardArrow': {
            cursor: 'pointer',
            bottom: '0',
            top: '-56px',
        },
        '& .backwardArrow': {
            right: 63,
        },
        '& .forwardArrow': {
            right: 13,
        },
    },
});

const NupV2CollectionSlider = ({
    classes, collections, arrowIconAdjust, headerSection,
}) => {
    const seed = useUIDSeed();
    const productShowBasedOnLength = collections?.length < 4 ? collections?.length : 4;
    const iconAdjustCss = arrowIconAdjust === 'top' ? classes.iconAdjustTopCss : null;
    const ffHasHomepageGridStructure = useSelector(getFeatureFlag('has-homepage-grid-structure'));
    return (
        <Grid container className={ffHasHomepageGridStructure ? classes.mainSliderContainerWithGrid : classes.mainSliderContainer}>
            <Slider
                dots={false}
                speed={150}
                infinite={false}
                slidesToShow={productShowBasedOnLength}
                slidesToScroll={1}
                nextArrow={<NavigationPaginate color="#000" className={classes} direction="forward" />}
                prevArrow={<NavigationPaginate color="#000" className={classes} direction="back" />}
                className={`carousel-container ${classes.sliderContainer} ${iconAdjustCss}`}
                responsive={[
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 599,
                        settings: {
                            dots: true,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            speed: 300,
                            centerMode: true,
                            centerPadding: '20%',
                            arrows: false,
                            infinite: true,
                            accessibility: false,
                            swipeToSlide: true,
                            dotsClass: 'slick-dots slick-thumb',
                            className: `carousel-container ${classes.sliderContainer}`,
                            customPaging: () => <div className={classes.dotNavigation} />,
                        },
                    },
                ]}
            >
                {collections?.map((item) => (
                    <div key={seed(item)} className={classes.slider}>
                        <NupV2Panel panelItem={item} headerSection={headerSection} />
                    </div>
                ))}
            </Slider>
        </Grid>
    );
};

NupV2CollectionSlider.propTypes = {
    headerSection: shape({
        copy_alignment: string.isRequired,
    }),
    classes: object.isRequired,
    collections: arrayOf(shape({
        linking: shape({
            tracking_event_category: string,
            tracking_event_label: string,
            tracking_event_action: string,
            link: shape({
                href: string.isRequired,
                title: string.isRequired,
            }).isRequired,
        }).isRequired,
        background_image: shape({
            url: string.isRequired,
        }),
        background_image_mobile: shape({
            url: string.isRequired,
        }),
    })),
    arrowIconAdjust: string.isRequired,
};
NupV2CollectionSlider.defaultProps = {
    headerSection: {},
    collections: [],
};
export default withStyles(styles)(NupV2CollectionSlider);

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { bindActionCreators } from 'redux';
import {
    object, shape, func, string, bool,
} from 'prop-types';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { connect, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import LazyLoad from 'react-lazyload';
import ReactMarkdown from 'react-markdown';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import cleanText from '../../../helpers/contentstack/cleanText';
import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import ResponsiveImage from '../../GraphqlComponents/GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import transformRenderer from '../../../helpers/markdown/characterTransformer';
import openInNewTab from '../../../helpers/contentstack/openInNewTab';
import { handleOnClick } from '../utils/pathUtils';
import { getIsBot } from '../../../../state/ducks/App/App-Selectors';
import { getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import CTA from '../Buttons/CTA';
import normalizeAltCopy from '../../../helpers/normalizeAltCopy';

const themes = {
    imageBorderRadius: {
        default: {
            borderRadius: 0,
        },
        variant_a: {
            borderRadius: 0,
        },
        variant_b: {
            borderRadius: 8,
        },
    },

    messageWrapperStyle: {
        default: {
            padding: '10px 0px 0px',
            width: '100%',
            display: 'flex',
            pTagmargin: '0px 0px 10px',
            pTagwidth: '95%',
        },
        variant_a: {
            textDecoration: 'underline',
            margin: '15px 0',
            visibility: 'hidden',
            pTagmargin: '0px 0px 10px',
        },
        variant_b: {
            textDecoration: 'none',
            margin: '8px 0',
            pTagmargin: '0px 0px 10px',
        },
    },
    copyVariantStyle: {
        variant_a: {
            width: '50%',
        },
    },
};

const getCtaPadding = (styles) => {
    let padding;
    if (styles?.cta?.cta_presentation === 'text') {
        padding = '8px 0 9px';
    } else {
        padding = '8px 20px 9px';
    }
    return padding;
};

const getCtaMarginTop = (styles) => {
    let margin;
    if (styles?.cta?.cta_presentation === 'text') {
        margin = '10px';
    } else {
        // padding + margin = 18px
        margin = '18px';
    }
    return margin;
};

const useStyles = makeStyles((theme) => ({
    panel: {
        fontSize: '20px',
        textDecoration: 'none',
        color: theme.palette.black,
        margin: '0 auto',
        '& a': {
            position: 'relative',
        },
    },

    messageWrapper: (styles) => ({
        padding: themes.messageWrapperStyle[styles?.presentation_style]?.padding || '',
        width: themes.messageWrapperStyle[styles?.presentation_style]?.width || 'auto',
        display: themes.messageWrapperStyle[styles?.presentation_style]?.display || '',
        textDecoration: themes.messageWrapperStyle[styles?.presentation_style]?.textDecoration || 'none',
        margin: themes.messageWrapperStyle[styles?.presentation_style]?.margin || '',
        '& p': {
            margin: themes.messageWrapperStyle[styles?.presentation_style]?.pTagmargin || '',
            width: themes.messageWrapperStyle[styles?.presentation_style]?.pTagwidth || '',
        },
        [theme.breakpoints.down(768)]: {
            visibility: themes.messageWrapperStyle[styles?.presentation_style]?.visibility || '',
        },
    }),

    copyVariant: (styles) => ({
        '& h1,h2,h3,h4,h5,h6': {
            margin: '16px 0px 0px',
            padding: 0,
        },
        '& p': {
            margin: '5px 0px 0px',
            padding: 0,
        },
        [theme.breakpoints.down(768)]: {
            width: themes.copyVariantStyle[styles?.presentation_style]?.width || '',
        },
    }),
    subCopy: {
        '& p': {
            margin: '0 auto 10px',
            fontSize: '13px',
        },
    },
    image: (styles) => ({
        margin: '0 auto 0',
        maxWidth: '100%',
        width: '100%',
        height: 'auto',
        minHeight: 'auto',
        display: 'block',
        borderRadius: themes.imageBorderRadius[styles?.presentation_style]?.borderRadius || 0,
    }),
    gradiantColor: {
        background: 'rgba(196, 196, 196, 2)',
        borderRadius: '50% 50% 0 0',
        overflow: 'hidden',
        boxSizing: 'border-box',
        position: 'relative',
        width: 300,
        height: 260,
        [theme.breakpoints.down(1500)]: {
            width: '100%',
            marginLeft: 0,
        },
        [theme.breakpoints.down(769)]: {
            height: 200,
        },
        '& img': {
            position: 'absolute',
            top: '0',
            left: '0',
            filter: 'blur(40px)',
            '-webkit-filter': 'blur(40px)',
            '-moz-filter': 'blur(40px)',
            '-o-filter': 'blur(40px)',
            '-ms-filter': 'progid:DXImageTransform.Microsoft.Blur(pixelradius=40, enabled=true)',
        },
    },
    blurImage: {
        position: 'relative',
        width: '100%',
        height: '100%',
        background: 'linear-gradient(110deg, #BFA7A2 60%, #B6AD89 60%)',
    },
    mainPassportImage: {
        position: 'absolute',
        top: 0,
        [theme.breakpoints.down(1442)]: {
            width: 234,
            marginLeft: 0,
        },
        '& img': {
            width: '100%',
            height: 265,
            padding: '20px 0px',
            marginLeft: 40,
            top: 0,
            filter: 'none',
            transform: 'rotate(0deg)',
            [theme.breakpoints.down(2561)]: {
                minHeight: 'auto',
                marginLeft: 20,
                width: '80%',
            },
            [theme.breakpoints.down(1442)]: {
                width: '100%',
                marginLeft: 50,
            },
            [theme.breakpoints.down(1200)]: {
                marginLeft: 10,
            },
            [theme.breakpoints.down(1060)]: {
                width: '100%',
                marginLeft: 0,
            },
            [theme.breakpoints.down(999)]: {
                width: 200,
                maxHeight: 256,
            },
            [theme.breakpoints.down(870)]: {
                width: 175,
                maxHeight: 246,
                marginLeft: 5,
            },
            [theme.breakpoints.down(769)]: {
                width: '70%',
                height: 210,
                marginLeft: 10,
            },
            [theme.breakpoints.down(768)]: {
                width: '80%',
                height: 210,
                marginLeft: 25,
            },
            [theme.breakpoints.down(700)]: {
                width: '70%',
            },
            [theme.breakpoints.down(700)]: {
                marginLeft: 10,
            },
            [theme.breakpoints.down(365)]: {
                width: '61%',
                height: 200,
                marginLeft: 10,
            },
        },
    },
    ctaImage: {
        width: '20px',
        display: 'flex',
    },
    floatingTag: {
        position: 'absolute',
        background: theme.palette.white,
        display: 'block',
        minWidth: 120,
        textAlign: 'center',
        padding: '5px 5px',
        fontSize: 14,
        fontWeight: 'bold',
        top: 20,
        left: 20,
    },
    subTitle: {
        marginTop: '16px',
        fontSize: 16,
        color: theme.palette.text.disabled,
        fontWeight: 'bold',
    },
    link: {
        cursor: 'pointer',
    },
    cta: (styles) => ({
        marginTop: getCtaMarginTop(styles),
        padding: getCtaPadding(styles),
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    }),
    ctaEndIcon: {
        marginLeft: 5,
        fontSize: '1em',
    },
}));

const NupV2Panel = ({
    panelItem, track, headerSection, isLink,
}) => {
    const history = useHistory();
    const isBot = useSelector(getIsBot);
    const ffSwapLinkWithOnclick = useSelector(getFeatureFlag('is-link-swapped-with-onclick-enabled'));
    const fontFamilyParsed = (fontData) => {
        if (!fontData) return '';
        const fontArr = fontData.split(':');
        if (fontArr?.[1]) {
            return fontArr[1].replace(';', '');
        }
        return '';
    };
    const imageStyle = panelItem?.presentation_style === 'variant_a';
    const copyAlignment = headerSection?.copy_alignment || 'center';
    const copyColor = panelItem?.copy_background_color?.color || '';
    const copyFontFamily = panelItem?.copy_font_family || '#000000';
    const ctaTitle = panelItem?.cta?.cta_copy.toUpperCase();
    let ctaChevron = false;

    const computeCtaColors = () => {
        // contains two values, color and background color
        let colorArray = [panelItem?.cta?.copy_color?.color, panelItem?.cta?.background_color?.color];
        if (panelItem?.cta?.cta_presentation === 'text') colorArray = [panelItem?.copy_font_family || '#000000', 'none'];
        return colorArray;
    };
    const [ctaColor, ctaBgColor] = computeCtaColors();

    const classes = useStyles(panelItem);

    const getCtaEndIcon = (iconType) => {
        let icon = '';
        switch (iconType) {
            case 'Triangle':
                ctaChevron = false;
                icon = <ArrowForwardIcon className={classes.ctaEndIcon} />;
                break;
            case 'None':
                ctaChevron = false;
                break;
            default:
                ctaChevron = true;
        }
        return icon;
    };

    const ctaCopy = (
        <>
            {panelItem?.cta?.cta_copy.toUpperCase()} {getCtaEndIcon(panelItem?.cta?.copy_icon_style)}
        </>);

    const renderLinkChild = () => (
        <>
            <div>
                {panelItem?.background_image || panelItem?.background_image_mobile
                    ? (
                        <>
                            {imageStyle && (panelItem?.background_image?.url?.length || panelItem?.background_image_mobile?.url?.length) && (
                                <>
                                    <div className={classes.gradiantColor}>
                                        <img src={panelItem?.background_image?.url} alt={panelItem?.background_image?.title || panelItem?.background_image_mobile?.title || 'Background Image'} />
                                    </div>
                                    <div className={classes.blurImage} />
                                </>
                            )}
                            <div className={imageStyle ? classes.mainPassportImage : classes.ResponsiveImage}>
                                <LazyLoad>
                                    {panelItem?.floating_tag ? (
                                        <div className={classes.floatingTag}>{panelItem?.floating_tag}</div>
                                    ) : null}
                                    <ResponsiveImage
                                        className={classes.image}
                                        path={panelItem?.background_image?.url || panelItem?.background_image_mobile?.url}
                                        alt={normalizeAltCopy(panelItem?.background_image?.title) || normalizeAltCopy(panelItem?.background_image_mobile?.title) || 'Nup Image'}
                                        dimensions={panelItem?.background_image?.dimension || panelItem?.background_image_mobile?.dimension}
                                        params={{}}
                                    />
                                </LazyLoad>
                            </div>
                        </>
                    ) : null}
            </div>
            <div>
                {panelItem?.copy_rich && (
                    <div
                        className={classes.copyVariant}
                        style={{
                            fontFamily: fontFamilyParsed(copyFontFamily),
                            textAlign: copyAlignment,
                            color: copyColor,
                        }}
                    >
                        <ReactMarkdown renderers={transformRenderer} source={cleanText(panelItem?.copy_rich)} escapeHtml={false} />
                    </div>
                )}
                {panelItem?.sub_title ? (
                    <div className={classes.subTitle}>{panelItem?.sub_title}</div>
                ) : null}
            </div>
            <div
                style={{
                    textAlign: copyAlignment,
                }}
            >
                {panelItem?.cta?.cta_copy && (
                    <CTA
                        className={classes.cta}
                        title={ctaTitle}
                        fontColor={ctaColor}
                        copy={ctaCopy}
                        backgroundColor={ctaBgColor}
                        chevron={ctaChevron}
                        border={false}
                    />
                )}
            </div>
        </>
    );
    return (
        <div className={classes.panel}>
            {/* Link component having issues to  if we have "&" in URL */}
            {(ffSwapLinkWithOnclick && !isBot && panelItem?.linking?.link?.href?.indexOf?.('&') >= 0) ? (
                <>
                    <div
                        className={classes.link}
                        data-ga-category={panelItem?.linking?.tracking_event_category || ''}
                        data-ga-action={panelItem?.linking?.tracking_event_action || ''}
                        data-ga-label={panelItem?.linking?.tracking_event_label || ''}
                        title={panelItem?.linking?.link?.title}
                        role="presentation"
                        onClick={(e) => {
                            track({
                                eventCategory: panelItem?.linking?.tracking_event_category || '',
                                eventAction: panelItem?.linking?.tracking_event_action || '',
                                eventLabel: panelItem?.linking?.tracking_event_label || '',
                            });

                            handleOnClick(e, panelItem?.linking?.link?.href, history);
                        }}
                    >
                        {renderLinkChild()}
                    </div>
                </>
            ) : (
                <>
                    {isLink ? (
                        <div
                            title={panelItem?.linking?.link?.title}
                        >
                            {renderLinkChild()}
                        </div>
                    )
                        : (
                            <Link
                                to={{ pathname: panelItem?.linking?.link?.href }}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...openInNewTab(panelItem?.linking?.link?.href)}
                                title={panelItem?.linking?.link?.title}
                                data-ga-category={panelItem?.linking?.tracking_event_category || ''}
                                data-ga-action={panelItem?.linking?.tracking_event_action || ''}
                                data-ga-label={panelItem?.linking?.tracking_event_label || ''}
                                onClick={() => {
                                    track({
                                        eventCategory: panelItem?.linking?.tracking_event_category || '',
                                        eventAction: panelItem?.linking?.tracking_event_action || '',
                                        eventLabel: panelItem?.linking?.tracking_event_label || '',
                                    });
                                }}
                            >
                                {renderLinkChild()}
                            </Link>
                        )}
                </>
            )}

        </div>
    );
};
NupV2Panel.propTypes = {
    panelItem: shape({
        linking: shape({
            tracking_event_category: string,
            tracking_event_label: string,
            tracking_event_action: string,
            link: shape({
                href: string.isRequired,
                title: string.isRequired,
            }).isRequired,
        }).isRequired,
        background_image: shape({
            url: string.isRequired,
        }),
        background_image_mobile: shape({
            url: string.isRequired,
        }),
    }).isRequired,
    headerSection: shape({
        heading: string.isRequired,
    }),
    classes: object.isRequired,
    track: func.isRequired,
    isLink: bool.isRequired,
};
NupV2Panel.defaultProps = {
    headerSection: {},
};

const mapDispatchToProps = (dispatch) => ({
    track: bindActionCreators(trackEvent, dispatch),
});

const enhance = compose(
    connect(null, mapDispatchToProps),
);
export default enhance(NupV2Panel);
